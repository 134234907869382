import * as actionTypes from './actionTypes';


const initialState = {
  menuOpen        : false,
  selectedMenuItem: null,
  agpView         : false,
  fetching        : [],
  sending         : [],
  errors          : [],
  windowWidth     : 0,
};


export default function reducer(state = { ...initialState }, action) {

  switch (action.type) {

    case actionTypes.SET_MENU_OPEN: {
      const { menuOpen } = action.payload;
      return {
        ...state,
        menuOpen,
      };
    }
    case actionTypes.SET_SELECTED_MENU_ITEM: {
      const { selectedMenuItem } = action.payload;
      return {
        ...state,
        selectedMenuItem,
      };
    }
    case actionTypes.SET_AGP_VIEW: {
      const { agpView } = action.payload;
      return {
        ...state,
        agpView,
      };
    }
    case actionTypes.SET_WINDOW_WIDTH: {
      const { windowWidth } = action.payload;
      return {
        ...state,
        windowWidth,
      };
    }
    default: {
      return state;
    }

  }
}
