import { put, takeLatest, select } from 'redux-saga/effects';
import LocalStorage from 'libs/LocalStorage';
import * as actionTypes from './actionTypes';
import * as actions from './actions';
import * as selectors from './selectors';
import * as constants from './constants';


function* setWindowWidth({ payload }) {
  const { windowWidth, shouldSetBaseMenuOpen } = payload;

  if (shouldSetBaseMenuOpen && windowWidth >= constants.PINNED_MENU_BREAKPOINT) {
    let isOpenMenu = LocalStorage.getItem('openPinnedMenu');
    if (isOpenMenu !== 'false' && !isOpenMenu) {
      isOpenMenu = true;
    } else {
      isOpenMenu = isOpenMenu === 'true';
    }

    yield put(actions.setMenuOpen(isOpenMenu));
  }
}


function* setMenuOpen({ payload }) {
  const { menuOpen } = payload;
  const windowWidth = yield select(selectors.windowWidth);

  if (windowWidth >= constants.PINNED_MENU_BREAKPOINT) {
    LocalStorage.setItem('openPinnedMenu', menuOpen);
  }
}


function* sagas() {
  yield takeLatest(actionTypes.SET_WINDOW_WIDTH, setWindowWidth);
  yield takeLatest(actionTypes.SET_MENU_OPEN, setMenuOpen);
}


export default [
  sagas,
];
