import * as actionTypes from './actionTypes';


export const setAgpView = (agpView) => ({
  type   : actionTypes.SET_AGP_VIEW,
  payload: {
    agpView,
  },
});


export const setMenuOpen = (menuOpen) => ({
  type   : actionTypes.SET_MENU_OPEN,
  payload: {
    menuOpen,
  },
});


export const setSelectedMenuItem = (selectedMenuItem) => ({
  type   : actionTypes.SET_SELECTED_MENU_ITEM,
  payload: {
    selectedMenuItem,
  },
});


export const setWindowWidth = (windowWidth, shouldSetBaseMenuOpen) => ({
  type   : actionTypes.SET_WINDOW_WIDTH,
  payload: {
    windowWidth,
    shouldSetBaseMenuOpen,
  },
});
