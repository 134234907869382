exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".vq2nP{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;min-height:100vh;width:100%;overflow-x:hidden}@media (min-width:1024px){.vq2nP{-ms-flex-direction:row;flex-direction:row}}.vq2nP svg path{vector-effect:non-scaling-stroke}._2HlEi{height:70px;height:7rem;width:100%;background:#872c93;display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:start;justify-content:flex-start}._3Uk7e{width:130px;margin:0 2rem}._3UMat{-ms-flex:1 1 auto;flex:1 1 auto;height:100vh}.R0OZo{display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center;height:100%}@media (min-width:1024px){.R0OZo{-ms-flex-align:center;align-items:center}}.R0OZo a{font-weight:700}.R0OZo .content{padding:3.5rem 2rem 0;font-size:14px;font-size:1.4rem;width:100%;max-width:640px;max-width:64rem}@media (min-width:600px){.R0OZo .content{padding:3.5rem 0 10rem;width:80%}}@media (min-width:1024px){.R0OZo .content{padding:5rem 0 0;width:50rem}}@media (min-width:1024px){.R0OZo .content--fixed{width:88rem!important}}.R0OZo .pageHeader{margin-bottom:4rem}.R0OZo .pageHeader__intro{font-size:14px;font-size:1.4rem;color:#6f7ea2;line-height:1.5;padding-top:1rem}.R0OZo .content--fixed{max-width:none!important}@media (min-width:1024px){.R0OZo .content--fixed{width:100rem!important}}@media (min-width:1280px){.R0OZo .content--fixed{width:110rem!important}}", ""]);

// exports
exports.locals = {
	"root": "vq2nP",
	"mobileLogo": "_2HlEi",
	"mobileLogo__logo": "_3Uk7e",
	"container": "_3UMat",
	"containerInner": "R0OZo"
};