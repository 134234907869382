/**
 * Local storage wrapper for SSR
 */
export default class LocalStorage {

  static setItem(key, data) {
    if (typeof localStorage !== 'undefined') {
      localStorage.setItem(key, data);
    }
  }

  static getItem(key) {
    if (typeof localStorage !== 'undefined') {
      return localStorage.getItem(key);
    }
    return null;
  }

  static removeItem(key) {
    if (typeof localStorage !== 'undefined') {
      return localStorage.removeItem(key);
    }
    return null;
  }

}
