exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "div:first-child>.alert{border-radius:8px}.BHmP9{min-height:100vh;width:100%;background:-webkit-gradient(linear,left top,right bottom,from(#95449f),to(#7a1587));background:-o-linear-gradient(top left,#95449f,#7a1587);background:linear-gradient(to bottom right,#95449f,#7a1587)}.BHmP9,.IG3x9{display:-ms-flexbox;display:flex}.IG3x9{max-width:30vw;margin:auto;-ms-flex-direction:column;flex-direction:column}._1nEoe{max-width:20vw;margin:0 auto}._2gYHp{margin-top:5rem;height:14px;height:1.4rem;border-radius:1.6rem;position:relative;background:#fff}._1hOh7{position:absolute;height:10px;height:1rem;border-radius:1.6rem;top:2px;top:.2rem;right:100%;bottom:0;left:0;background:#d770d7;width:0;-webkit-animation:fYb_o 2s linear infinite;animation:fYb_o 2s linear infinite}@-webkit-keyframes fYb_o{0%{left:0;right:100%;width:0}10%{left:0;right:80%;width:20%}90%{right:0;left:80%;width:20%}to{left:100%;right:0;width:0}}@keyframes fYb_o{0%{left:0;right:100%;width:0}10%{left:0;right:80%;width:20%}90%{right:0;left:80%;width:20%}to{left:100%;right:0;width:0}}", ""]);

// exports
exports.locals = {
	"root": "BHmP9",
	"root__container": "IG3x9",
	"root__logo": "_1nEoe",
	"root__loader": "_2gYHp",
	"root__loaderBar": "_1hOh7",
	"infinite-bar": "fYb_o"
};