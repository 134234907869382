exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._28QtV{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;min-height:100vh;width:100%;overflow-x:hidden}@media (min-width:1024px){._28QtV{-ms-flex-direction:row;flex-direction:row}}._28QtV svg path{vector-effect:non-scaling-stroke}._3JQjN{height:70px;height:7rem;width:100%;background:#872c93;display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:start;justify-content:flex-start}._1nncJ{width:130px;margin:0 2rem}.oPa06{-ms-flex:1 1 auto;flex:1 1 auto;height:100vh}._1tH4G{display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center;height:100%}@media (min-width:1024px){._1tH4G{-ms-flex-align:center;align-items:center}}._1tH4G a{font-weight:700}._1tH4G .content{padding:3.5rem 2rem 0;font-size:14px;font-size:1.4rem;width:100%;max-width:640px;max-width:64rem}@media (min-width:600px){._1tH4G .content{padding:3.5rem 0 10rem;width:80%}}@media (min-width:1024px){._1tH4G .content{padding:5rem 0 0;width:50rem}}._1tH4G .content--fixed{max-width:none!important}@media (min-width:1024px){._1tH4G .content--fixed{width:88rem!important}}._1tH4G .pageHeader{margin-bottom:4rem}._1tH4G .pageHeader__intro{font-size:14px;font-size:1.4rem;color:#6f7ea2;line-height:1.5;padding-top:1rem}div:first-child>.alert{border-radius:8px}._1j4lA{height:100%;background:#872c93;display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center}._2iRWO{position:fixed;top:0;height:100vh;background:#872c93}._3SDoD{width:200px;width:20rem;margin-top:calc(50vh - 23px)}", ""]);

// exports
exports.locals = {
	"root": "_28QtV",
	"mobileLogo": "_3JQjN",
	"mobileLogo__logo": "_1nncJ",
	"container": "oPa06",
	"containerInner": "_1tH4G",
	"logoSidebar": "_1j4lA",
	"logoSidebar__container": "_2iRWO",
	"logoSidebar__logo": "_3SDoD"
};