import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import App from 'modules/App';
import messages from '../../messages';
import styles from './LoaderLayout.pcss';


class LoaderLayout extends React.Component {

  static propTypes = {
    // Explicit props
    metaTitleMessage      : PropTypes.object,
    metaDescriptionMessage: PropTypes.object,
    // Implicit props
  };


  render() {
    const titleMessage = this.props.metaTitleMessage
      ? this.props.metaTitleMessage
      : { ...messages.meta.title };

    const descriptionMessage = this.props.metaDescriptionMessage
      ? this.props.metaDescriptionMessage
      : { ...messages.meta.description };

    return (
      <App.components.LanguageProvider>
        <div className={styles.root}>
          <App.components.IntlHelmet
            titleMessage={titleMessage}
            descriptionMessage={descriptionMessage}
          />
          <div className={cn('col col-lg-8', styles.root__container)}>
            <img className={styles.root__logo} src="/aid/assets/svg/logo.svg" alt="Ascensia Diabetes Care" />
            <div className={styles.root__loader}>
              <div className={styles.root__loaderBar} />
            </div>
            <App.components.AlertsBus className="mt-5" />
          </div>
        </div>
      </App.components.LanguageProvider>
    );
  }

}


export default withStyles(styles)(LoaderLayout);
